import com from '@logic/language/com/landing/index/FAQ_NEW';
import ru from '@logic/language/ru/landing/index/FAQ_NEW';
import uz from '@logic/language/uz/landing/index/FAQ_NEW';


export default {
    ru,
    com,
    uz,
};


