const FAQ_NEW = {
    title: {
        ru: 'Вопрос-ответ',
        en: 'FAQ',
        uz: 'Savol-javob',
        lt: '',

    },
    applicationTitle: {
        ru: 'Задайте свой вопрос',
        en: 'Ask a question',
        uz: 'Savolingizni so\'rang',
        lt: ''
    },
    question1: {
        ru: 'Какую отчетность вы предоставляете?',
        en: 'How do you provide financial reports?',
        lt: '',
        uz: 'Siz qanday hisobotlarni taqdim etasiz? '
    },
    answer1: {
        ru: 'После отчетного периода пришлем полный пакет закрывающих документов по каждой выполненной работе и оказанной услуге.',
        en: 'After the reporting period, we will send you a full package of closing documents for each completed work and rendered service.',
        lt: '',
        uz: 'Hisobot davridan keyin har bir bajarilgan ish va ko\'rsatilgan xizmat bo’yicha yakuniy hujjatlarning to\'liq paketini yuboramiz. '
    },
    question2: {
        ru: 'Как платить фрилансеру, который находится в другой стране?',
        en: 'How do I pay a freelancer who is in another country?',
        lt: '',
        uz: 'Boshqa mamlakatda  bo\'lgan frilanserga to\'lovni qanday amalga oshirish  kerak? '
    },
    answer2: {
        ru: 'В личном кабинете сервиса Pay Assistant можно совершать выплаты фрилансерам со всего мира в разных валютах. Сервис работает с большинством платёжных систем — VISA, MasterCard, электронные кошельки и другие.',
        en: 'You can make payments to freelancers from around the world in different currencies in your Pay Assistant service profile. Our service supports the majority of payment systems — VISA, MasterCard, E-wallets and others.',
        lt: '',
        uz: 'Pay Assistant servis xizmatining shaxsiy  kabinetida frilanserlarga butun dunyo bo\'ylab to\'lovlarni turli valyutalarda  amalga  oshirish  mumkin. Servis xizmati - VISA, MasterCard, E-wallets va boshqa ko\'plab  to\'lov tizimlari  bilan ishlaydi. '
    },
    question3: {
        ru: 'Сколько времени понадобится, чтобы освоить и настроить сервис?',
        en: 'How much time does it take to understand and customise Pay Assistant?',
        lt: '',
        uz: 'Servis xizmatini  o\'zlashtirish va  sozlash  uchun  qancha vaqt  kerak bo’ladi?'
    },
    answer3: {
        ru: 'У Pay Assistant простой и понятный интерфейс. Наши клиенты тратят на начальные процессы не больше 1 часа. Если возникнут сложности — вы всегда можете обратиться в круглосуточную поддержку и к персональному менеджеру.',
        en: 'Pay Assistant has a simple and clear interface. Our clients spend less than an hour on initial process. If there are any issues — you can always contact the 24/7 support and a personal manager.',
        lt: '',
        uz: 'Pay Assistant tizimi oddiy  va intuitive – tushunarli interfeysga  ega. Bizning  mijozlarimiz dastlabki jarayonlarga 1 soatdan  ortiq  vaqt  sarflamaydilar. Agar  qiyinchiliklar yuzaga  kelsa – siz har  doim  kechayu kunduz  qo\'llab-quvvatlash xizmati va shaxsiy  menejerga  murojaat  qilishingiz  mumkin. '
    },
    question4: {
        ru: 'Безопасно ли использовать сервис?',
        en: 'Is Pay Assistant secure?',
        lt: '',
        uz: 'Servis xizmatidan foydalanish xavfsizmi?'
    },
    answer4: {
        ru: 'Pay Assistant защищает персональные данных согласно Политике конфиденциальности. На сайте производится проверка пользователей, а также двухфакторная аутентификация  — при входе в личный кабинет и для подтверждения выплаты нужно вводить пароль. При передаче результатов интеллектуальной деятельности действует сквозное шифрование файлов. Предусмотрен унифицированный API для карт и кошельков. Можете быть уверены — ваши данные и выплаты будут в безопасности!',
        en: 'Pay Assistant protects personal data in accordance with the Privacy Policy. There is a user verification, as well as two-factor authentication processes — you need to enter a password every time you log in your profile or initiate the payment. End-to-end file encryption is applied when transferring the results of intellectual activity. There is a unified API for cards and wallets. You can be sure that your personal information and vendor payments will be safe!',
        lt: '',
        uz: 'PayAssistant Maxfiylik siyosatiga muvofiq shaxsiy ma\'lumotlarni himoya qiladi. Saytda foydalanuvchilar tekshiruvi, shuningdek, ikki faktorli autentifikatsiya amalga oshiriladi – shaxsiy kabinetga kirganda va to\'lovni tasdiqlash uchun parolni kiritish kerak. Intellektual faoliyat natijalarini uzatishda faylni oxirigacha shifrlash amal qiladi. Karta va hamyonlar uchun unifikatsiya qilingan  API ko’zda tutilgan. Siz haqingizdagi ma\'lumotlar va to\'lovlar xavfsiz ekanligiga ishonch hosil qilishingiz mumkin! '
    },
    question5: {
        ru: 'Сколько стоит подключение к сервису?',
        en: 'How much does it cost to register with Pay Assistant?',
        lt: '',
        uz: 'Servis xizmatiga ulanish qancha turadi? '
    },
    answer5: {
        ru: 'Подключиться к Pay Assistant можно абсолютно бесплатно. Вы платите только процент от выплат — нет никаких скрытых комиссий.',
        en: 'You can connect to Pay Assistant for free. You only pay a share of your payouts — there are no hidden fees.',
        lt: '',
        uz: 'PayAssistant tizimiga mutlaqo bepul ulanish mumkin. Siz to\'lovlarning faqat bir foizini to\'laysiz - hech qanday yashirin komissiya to\'lovlari mavjud emas. '
    },
    askQuestion: {
        ru: 'Задать вопрос',
        en: 'Ask a question',
        lt: '',
        uz: 'Savol berish',
    }
};

export default FAQ_NEW;