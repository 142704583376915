

import com from '@logic/language/com/landing/index/FAQ';
import ru from '@logic/language/ru/landing/index/FAQ';
import uz from '@logic/language/uz/landing/index/FAQ';


export default process.env.NEXT_PUBLIC_SITE === 'ru' ? ru : process.env.NEXT_PUBLIC_SITE === 'com' ? com : uz;


