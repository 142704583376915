
const FAQ = {
    question1: {
        ru: `Какую отчетность для моей компании вы предоставите?`,
        en: `What kind of reporting can you provide to my company?`,
        lt: `Kokias ataskaitas pateiksite mano įmonei?`,
        uz: `Siz mening kompaniyam uchun qanday hisobot taqdim qilasiz?`,
    },
    answer1: {
        ru: `После отчетного периода мы предоставляем полный пакет закрывающих документов по каждой выполненной работе и оказанной услуге.`,
        en: `After the reporting period, we provide a full package of closing index for each work performed and service. provided.`,
        lt: `Ataskaitinio laikotarpio pabaigoje pateikiame pilną užbaigimo dokumentų komplektą  už kiekvieną atliktą darbą ir suteiktą paslaugą.`,
        uz: `Hisobot davridan so'ng, biz har bir bajarilgan ish va ko'rsatilgan xizmat bo'yicha yopuvchi hujjatlarning to'liq paketini taqdim qilamiz.`
    },
    question2: {
        ru: `Если у меня 100 фрилансеров, то придется каждого приглашать в сервис отдельно?`,
        en: `If I have 100 freelancers, do I have to invite each one of them to the service separately?`,
        lt: `Jei turiu 100 frilanserių, ar turiu kiekvieną jų atskirai pakviesti į servisą?`,
        uz: `Agar menda 100 ta frilanser bo'lsa, xizmatga ularning har birini alohida taklif qilishim kerakmi?`
    },
    answer2: {
        ru: `Нет, вам не придется приглашать фрилансеров по отдельности. Вы можете загрузить много исполнителей списком в формате .xls / .csv или интегрироваться с сервисом по API.`,
        en: `No, you don't have to invite all your freelancers separately. You can upload a list of your contractors in the .xls / .csv format or integrate with the service via API.`,
        lt: `Ne, frilanserių kiekvieną atskirai kviesti nereikia. Galite įkelti daugelio atlikėjų sąrašą .xls / .csv formatu arba integruotis su servisu per API.`,
        uz: `Yo'q, siz frilanserlarni alohida taklif qilishingiz shart bo'lmaydi. Siz .xls / .csv formatidagi ro'yxat bilan ko'plab ijrochilarni yuklashingiz yoki API orqali xizmat bilan integratsiya qilishingiz mumkin.`,
    },
    question3: {
        ru: 'Сколько времени понадобится, чтобы освоить и настроить сервис?',
        en: 'How much time does it take to understand and customise Pay Assistant?',
        lt: '',
        uz: 'Servis xizmatini  o\'zlashtirish va  sozlash  uchun  qancha vaqt  kerak bo’ladi?'
    },
    answer3: {
        ru: 'У Pay Assistant простой и понятный интерфейс. Наши клиенты тратят на начальные процессы не больше 1 часа. Если возникнут сложности — вы всегда можете обратиться в круглосуточную поддержку и к персональному менеджеру.',
        en: 'Pay Assistant has a simple and clear interface. Our clients spend less than an hour on initial process. If there are any issues — you can always contact the 24/7 support and a personal manager.',
        lt: '',
        uz: 'Pay Assistant tizimi oddiy  va intuitive – tushunarli interfeysga  ega. Bizning  mijozlarimiz dastlabki jarayonlarga 1 soatdan  ortiq  vaqt  sarflamaydilar. Agar  qiyinchiliklar yuzaga  kelsa – siz har  doim  kechayu kunduz  qo\'llab-quvvatlash xizmati va shaxsiy  menejerga  murojaat  qilishingiz  mumkin. '
    },
    question4: {
        ru: `Кто в данной модели является плательщиком НДФЛ?`,
        en: `Who is the payer of personal income tax in this model?`,
        lt: `Kas šiame modelyje yra fizinių asmenų pajamų mokesčio mokėtojas?`,
        uz: `Ushbu modelda kim shaxsiy daromad solig'i to'lovchisi bo'ladi?`,
    },
    answer4: {
        ru: `На основании оферты между Pay assistant и исполнителем, вся обязанность по декларированию и уплате НДФЛ лежит на исполнителе.`,
        en: `Based on the offer between Pay Assistant and the contractor, the contractor is fully obliged to declare and pay personal income tax.`,
        lt: `Remiantis pasiūlymu sudaryti civilinės teisės sutartį („oferta“) tarp serviso „Pay Assistant“ ir atlikėjo, visi įsipareigojimai deklaruoti ir sumokėti fizinių asmenų pajamų mokestį tenka atlikėjui.`,
        uz: `Pay Assistant va ijrochi o'rtasidagi ofertaga asosan, Shaxsiy daromad solig'ini deklaratsiya qilish va to'lash majburiyati ijrochiga yuklatiladi`
    },
    question5: {
        ru: `Фрилансеры, список которых мы загружаем в личный кабинет, видны другим заказчикам?`,
        en: `Is the list of our freelancers, that we upload to the personal account, visible to other customers?`,
        lt: `Ar frilanserių, kurių sąrašą įkeliame į asmeninį kabinetą, sąrašas matomas kitiems užsakovams?`,
        uz: `Biz shaxsiy kabinetga yuklaydigan frilanserlar ro'yxati boshqa buyurtmachilarga ko'rinadimi?`
    },
    answer5: {
        ru: `Нет. Фрилансеры заказчика видны только заказчику, данная информация третьим лицам не передаётся.`,
        en: `No. Customer's freelancers are visible only to the customer. This information is not transferred to third parties.`,
        lt: `Ne. Užsakovo frilanseriai matomi tik užsakovui, ši informacija neperduodama trečiosioms šalims.`,
        uz: `Yo'q. Buyurtmachining frilanserlari, faqat buyurtmachining o'ziga ko'rinadi, ushbu ma'lumot uchinchi shaxslarga berilmaydi.`
    },
    titleText: {
        ru: `Вопрос-ответ`,
        en: `FAQ`,
        lt: `Klausimas-atsakymas`,
        uz: `Savol javob`,
    },
    mainTitle: {
        ru: `Часто задаваемые вопросы`,
        en: `FAQ`,
        lt: `DUK`,
        uz: `KSS`,
    },
};

export default FAQ;
